import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createDocument,
  deleteDocument,
  getCollection,
  getDocument,
  snapshot,
  api
} from "../config";
import configureStore from "../configureStore";
import moment from "moment";

export const listenAccount = createAsyncThunk(
  "settingAccount/listenAccount",
  async (ref,{dispatch,rejectWithValue}) => {
    try{
      const listener = snapshot('user_accounts',ref.id,(d)=>{
        if(d.exists) dispatch(updateAccount(d));
      },{includeMetadataChanges:true});
      return {
        id:ref.id,
        listener:listener
      }
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const getAccounts = createAsyncThunk(
  "settingAccount/getAccounts",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getCollection("user_accounts", {
        where: [
          ["userID", "==", user.id],
          ["accountType.stripe", "==", true],
        ],
      });
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const getLocations = createAsyncThunk(
  "settingAccount/getLocations",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getCollection("locations", {
        where: [["users." + user.id, "==", true]],
      });
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const getSubscription = createAsyncThunk(
  "settingAccount/getSubscription",
  async (_,{getState,rejectWithValue}) => {
    try{
      let {account} = getState();
      let {user} = account;
      let rs = await getDocument("user_subscriptions", user.id);
      return rs;
    }catch(e){
      return rejectWithValue(e.message);
    } 
  }
);

export const loadSecrets = createAsyncThunk(
  "settingAccount/loadSecrets",
  async (_,{getState,rejectWithValue,dispatch}) => {
    try{
      let {account,settingsAccount} = getState();
      let {user,subscription} = account;

      const token = await api.userPlaidToken();

      const data = {...token.data.data}

      dispatch(setPlaidLinkToken(data.link_token));

      if (typeof window !== "undefined") {
        window.linkHandler = window.Plaid.create({
          token: data.link_token,
          onExit: function (err, metadata) {
            if (err != null) {
              console.log(err)
            }
            dispatch(setAddingACH(false));
          },
          onLoad: function(){
            dispatch(setMethodsError(null))
          },
          onSuccess: function (public_token, metadata) {
            let accountID = metadata.account.id;
            delete metadata.account.id;
            let data = {
              accountType: { stripe: true },
              publicToken: public_token,
              userID: user.id,
              institution: metadata.institution,
              accountID:accountID,
              ...metadata.account,
            };
            getCollection("user_accounts", {
              where: [
                ["userID", "==", user.id],
                ["accountType.stripe", "==", true],
              ],
            }).then((accounts) => {
              if (accounts.length == 0) data.default = true;
              return api.userAccountAdd(data);
            }).then((ref) => {
                dispatch(listenAccount(ref.data));
                dispatch(getAccounts());
                dispatch(setAddingACH(false));
              })
              .catch((err) => {
                dispatch(setMethodsError(err.message));
                dispatch(setAddingACH(false));
              });
          },
          product: ["auth"],
        });
        dispatch(setLoadingSecrets(false));
      }
      return;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);    

export const cancelSubscription = createAsyncThunk(
  "settingAccount/cancelSubscription",
  async (_,{dispatch,rejectWithValue}) => {
    try {
      const res = await api.userSubscriptionCancel();
      await dispatch(getSubscription());
      return res;

    }catch(e){
      return rejectWithValue(e.message);
    }

  }
);

export const activateSubscription = createAsyncThunk(
  "settingAccount/activateSubscription",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      
      const res = await api.userSubscriptionActivate();
      await dispatch(getSubscription());
      return;

    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const removeAccount = createAsyncThunk(
  "settingsAccount/removeAccount",
  async (_, { getState, rejectWithValue }) => {
    const { settingsAccount, account } = getState();
    const { selectedAccount, listeners } = settingsAccount;
    try {
      if(selectedAccount.id in listeners) listeners[selectedAccount.id]();
      await deleteDocument("user_accounts", selectedAccount.id);
      return;
    }catch(e){
      return rejectWithValue(e.message);
    }
  }
);

export const settingAccountSlice = createSlice({
  name: "settingAccount",
  initialState: {
    loading: false,
    loadingError: null,
    loadingAccounts: true,
    loadingLocations: true,
    loadingSubs: true,
    addingACH:false,
    listeners:{},
    cancelled: null,
    updated: null,
    accounts: [],
    locations: {},
    subscription: { brands: [] },
    plan: {
      locations: 0,
      price: 0,
    },
    currentPlan: "starter",
    stripe: null,
    elements: null,
    cancelling: false,
    showCancelModal: false,
    updating: false,
    savingCard: false,
    showRemoveAccountModal: false,
    selectedAccount: null,
    deleting: false,
    deletingError: null,
    activateError: null,
    cancelError: null,
    methodsError: null,
    showChangeDefaultModal: false,
    showChangeDefaultPaymentsModal: false,
    showTerms: false,
    saving: false,
  },
  reducers: {
    setCancelError: (state, action) => {
      state.cancelError = action.payload;
    },
    setMethodsError: (state, action) => {
      state.methodsError = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setAddingACH: (state, action) => {
      state.addingACH = action.payload;
    },
    setLoadingError: (state, action) => {
      state.loadingError = action.payload;
    },
    setStripe: (state, action) => {
      state.stripe = action.payload;
    },
    setElements: (state, action) => {
      state.elements = action.payload;
    },
    setUpdated: (state, action) => {
      state.updated = action.payload;
    },
    setSubscriptions: (state, action) => {
      state.subscription = action.payload;
    },
    setShowCancelModal: (state, action) => {
      state.showCancelModal = action.payload;
    },
    setAccounts: (state, action) => {
      state.accounts = action.payload;
    },
    setSavingcard: (state, action) => {
      state.savingCard = action.payload;
    },
    setShowRemoveAccountModal: (state, action) => {
      state.deletingError = null;
      state.showRemoveAccountModal = action.payload;
    },
    setSelectedAccount: (state, action) => {
      state.selectedAccount = action.payload;
    },
    setShowChangeDefaultModal: (state, action) => {
      state.showChangeDefaultModal = action.payload;
    },
    setShowChangeDefaultPaymentsModal: (state, action) => {
      state.showChangeDefaultPaymentsModal = action.payload;
    },
    setShowTerms: (state, action) => {
      state.showTerms = action.payload;
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
    updateAccount: (state, action) => {
      if(!action.payload.exists) return;
      let data = action.payload.data();
      state.accounts = [...state.accounts].map(a=>{
        let acc = {...a};
        if(acc.id===action.payload.id) {
          return {
            id:action.payload.id,
            ...data
          }
        }else{
          return {...acc};
        }
      });
    }
  },
  extraReducers: {
    [listenAccount.fulfilled]: (state, action) => {
      state.listeners[action.payload.id] = action.payload.listener;
      return;
    },
    [listenAccount.rejected]: (state, action) => {
      console.log(action.payload)
      return;
    },
    [getAccounts.fulfilled]: (state, action) => {
      state.accounts = action.payload;
      state.loadingAccounts = false;
      return;
    },
    [getAccounts.pending]: (state, action) => {
      state.loadingAccounts = true;
      return;
    },
    [getAccounts.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingAccounts = false;
      return;
    },
    [getLocations.fulfilled]: (state, action) => {
      let locat = action.payload;
      let planLocation = 0;
      let l = {};
      locat.forEach((location) => {
        if ((!location.deleted)&&(location.status!=='Referral')) {
          planLocation++;
          if (!(location.brandID in l)) l[location.brandID] = 0;
          l[location.brandID]++;
        }
      });
      state.locations = l;
      state.plan.locations = planLocation;
      state.loadingLocations = false;
      return;
    },
    [getLocations.pending]: (state, action) => {
      state.loadingLocations = true;
      return;
    },
    [getLocations.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingLocations = false;
      return;
    },
    [getSubscription.fulfilled]: (state, action) => {
      if (action.payload) {
        let subs = action.payload;
        if (action.payload.invoices && action.payload.invoices.length > 0) {
          subs.invoices = subs.invoices.map((inv) =>
            Object.assign({ downloading: false, opening: false }, { ...inv })
          );
        }
        state.subscription = subs;
        state.currentPlan = state.subscription?.plan||'starter';
      } else {
        const { account } = configureStore().getState();
        const { user } = account;
        let sortedBrands = [];
        if (user && user.brands) {
          sortedBrands = user.brands.sort((a, b) =>
            a.brand > b.brand ? 1 : b.brand > a.brand ? -1 : 0
          );
        } else {
          sortedBrands = [];
        }
        let subscribedBrands = {};
        sortedBrands.forEach((b) => (subscribedBrands[b.id] = { ...b }));
        state.subscription.brands = subscribedBrands;
      }
      state.loadingSubs = false;
      return;
    },
    [getSubscription.pending]: (state, action) => {
      state.loadingSubs = true;
      return;
    },
    [getSubscription.rejected]: (state, action) => {
      state.loadingError = action.payload;
      state.loadingSubs = false;
      return;
    },
    [cancelSubscription.fulfilled]: (state, action) => {
      state.showCancelModal = false;
      state.cancelling = false;
    },
    [cancelSubscription.pending]: (state, action) => {
      state.cancelling = true;
      state.cancelError = null;
      return;
    },
    [cancelSubscription.rejected]: (state, action) => {
      state.cancelling = false;
      state.cancelError = action.payload;
    },
    [activateSubscription.fulfilled]: (state, action) => {
      state.updating = false;
    },
    [activateSubscription.pending]: (state, action) => {
      state.activateError = null;
      state.updating = true;
    },
    [activateSubscription.rejected]: (state, action) => {
      state.activateError = action.payload;
      state.updating = false;
      return;
    },
    [removeAccount.fulfilled]: (state, action) => {
      state.deleting = false;
      state.accounts = [...state.accounts].filter(
        (a) => a.id !== state.selectedAccount.id
      );
      state.selectedAccount = null;
      state.showRemoveAccountModal = false;
      return;
    },
    [removeAccount.pending]: (state, action) => {
      state.deleting = true;
      state.deletingError = null;
      return;
    },
    [removeAccount.rejected]: (state, action) => {
      state.deletingError = action.payload;
      state.deleting = false;
      return;
    },
  },
});

export const {
  setLoading,
  setLoadingSecrets,
  setLoadingError,
  setStripe,
  setElements,
  setPlaidLinkToken,
  setUpdated,
  setSubscriptions,
  setShowCancelModal,
  setAccounts,
  setSavingcard,
  setShowRemoveAccountModal,
  setSelectedAccount,
  setShowChangeDefaultModal,
  setShowChangeDefaultPaymentsModal,
  setSaving,
  setShowTerms,
  updateAccount,
  setMethodsError,
  setAddingACH,
  setCancelError
} = settingAccountSlice.actions;
export default settingAccountSlice.reducer;
